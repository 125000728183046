import React, { Suspense, lazy, useState } from "react";

//Custom Components
import OTPVerify from "./OTPVerify";
import Loader from "../../utils/Loader";
const Signup = lazy(()=>import ('./Signup'));

const SignupFlow = (props) => {
    const [userEmail, setUserEmail] = useState(false);
    const [otpStatus, setOtpStatus] = useState(false);
    const [password, setPassword] = useState("");
    const [orgType, setOrgType] = useState("");

    return (
    <>
      <Suspense fallback={<Loader/>}>
        {!userEmail && <Signup setUserEmail={setUserEmail} setUserPassword={setPassword} setOrgType={setOrgType} />}
      </Suspense>      
        {userEmail && <OTPVerify email={userEmail} setUserEmail={setUserEmail} orgType={orgType} password={password} reset={false} />}
    </>
    );
};

export default SignupFlow;
